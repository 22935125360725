<script setup lang="ts">
  import type { PloneDocument } from '~/types/Plone'
  const props = defineProps<{ data: PloneDocument }>()
  const columns = props.data['@type'] === 'Folder'
    ? 4
    : 1
</script>

<template>
  <app-page
    :data="data"
    :hide-title="data.hide_title"
    :hide-description="data.hide_description"
    :hide-image="data.hide_image">
    <app-container narrow>
      <card-group
        :columns="columns"
        :items="data.items"/>
    </app-container>
  </app-page>
</template>
